"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var defaultProps = {
    display: {
        grid: false,
        gridGap: "20px",
        gridMinColumnWidth: "200px",
        row: false,
        rowGap: "20px",
    },
    displayGrid: false,
    displayRow: false,
    gridGap: "20px",
    minColumnWidth: "200px",
    rowGap: "20px",
};
function DisplayHandler(props) {
    var _a = __assign(__assign({}, defaultProps), props), displayGrid = _a.displayGrid, displayRow = _a.displayRow, display = _a.display, gridGap = _a.gridGap, minColumnWidth = _a.minColumnWidth, rowGap = _a.rowGap;
    var childSpanRef = (0, react_1.createRef)();
    var _b = (0, react_1.useState)(), combo = _b[0], setParentComponent = _b[1];
    var styleParentGrid = function (styleTag, container) {
        if (displayGrid || display.grid) {
            var gap = display.gridGap || gridGap || defaultProps.display.gridGap;
            var column = display.gridMinColumnWidth ||
                minColumnWidth ||
                defaultProps.display.gridMinColumnWidth;
            styleTag.innerHTML = "\n          [data-cont=\"".concat(container.dataset.cont, "\"] {\n              display: grid;\n              grid-gap: ").concat(gap, ";\n              gap: ").concat(gap, ";\n              grid-template-columns: repeat(auto-fill, minmax(").concat(column, ", 1fr));\n              grid-template-rows: auto;\n              align-items: stretch;\n          }\n          \n          [data-cont=\"").concat(container.dataset.cont, "\"] .__infinite-loader,\n          [data-cont=\"").concat(container.dataset.cont, "\"] .___scroll-renderer-anchor,\n          [data-cont=\"").concat(container.dataset.cont, "\"] .___list-separator {\n              grid-column: 1/-1;\n          }\n      ");
        }
        else {
            styleTag.innerHTML = "";
        }
    };
    var styleParentRow = function (styleTag, container) {
        if (displayRow || display.row) {
            var gap = display.rowGap || rowGap || defaultProps.display.rowGap;
            styleTag.innerHTML = "\n          [data-cont=\"".concat(container.dataset.cont, "\"] {\n              display: flex;\n              flex-direction: column;\n          }\n          \n          [data-cont=\"").concat(container.dataset.cont, "\"] > *:not(.__infinite-loader) {\n              display: block;\n              flex: 1;\n              width: 100%;\n              margin-bottom: ").concat(gap, ";\n          }\n      ");
        }
        else {
            styleTag.innerHTML = "";
        }
    };
    var handleDisplayHandlerProps = function (container, style) {
        if (container) {
            if (display.grid || displayGrid) {
                styleParentGrid(style, container);
            }
            else if (display.row || displayRow) {
                styleParentRow(style, container);
            }
        }
    };
    (0, react_1.useEffect)(function () {
        if (combo) {
            handleDisplayHandlerProps(combo[0], combo[1]);
        }
    });
    (0, react_1.useEffect)(function () {
        var current = childSpanRef.current;
        var container;
        var style;
        if (current) {
            var id = "__container-".concat(new Date().getTime());
            container = current.parentNode;
            if (container) {
                container.setAttribute("data-cont", id);
                style = document.createElement("STYLE");
                style.id = id;
                document.head.appendChild(style);
                setParentComponent([container, style]);
                handleDisplayHandlerProps(container, style);
            }
        }
        else {
            console.warn("FlatList: it was not possible to get container's ref. Styling will not be possible");
        }
        return function () {
            if (style) {
                style.remove();
            }
        };
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null, !combo && react_1.default.createElement("span", { ref: childSpanRef, style: { display: "none" } })));
}
exports.default = DisplayHandler;
