"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var isType_1 = require("./isType");
var limitList = function (list, limit, to) {
    if (limit === void 0) { limit = 0; }
    if (to === void 0) { to = undefined; }
    if (!limit || Number(limit) <= 0 || Number(limit) >= list.length) {
        return list;
    }
    if (to === undefined) {
        return list.slice(0, Number(limit));
    }
    return !to || !(0, isType_1.isNumber)(to) || Number(to) === 0
        ? list.slice(Number(limit))
        : list.slice(Number(limit), Number(to));
};
exports.default = limitList;
