"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var uiFunctions_1 = require("./uiFunctions");
function ScrollRenderer(props) {
    var _a = props.list, list = _a === void 0 ? [] : _a, _b = props.renderItem, renderItem = _b === void 0 ? function () { return null; } : _b, _c = props.groupSeparator, groupSeparator = _c === void 0 ? null : _c;
    var _d = (0, react_1.useState)({ renderList: [], index: 0 }), render = _d[0], setRender = _d[1];
    var _e = (0, react_1.useState)(false), mounted = _e[0], setMounted = _e[1];
    var _f = (0, react_1.useState)(-1), setupCount = _f[0], setSetupCount = _f[1];
    var containerRef = (0, react_1.createRef)();
    var renderThisItem = (0, react_1.useMemo)(function () {
        return (0, uiFunctions_1.handleRenderItem)(renderItem, (0, uiFunctions_1.handleRenderGroupSeparator)(groupSeparator));
    }, [renderItem, groupSeparator]);
    var updateRenderInfo = function (count) {
        if (count === void 0) { count = 10; }
        if (render.index < list.length) {
            var index = render.index + count;
            setRender({
                renderList: list.slice(0, index),
                index: index,
            });
        }
    };
    var onScroll = function (span) { return function () {
        requestAnimationFrame(function () {
            if (span) {
                var parent_1 = span.parentNode;
                var startingPoint = parent_1.offsetTop + parent_1.offsetHeight;
                var anchorPos = span.offsetTop - parent_1.scrollTop;
                if (anchorPos <= startingPoint + parent_1.offsetHeight * 2) {
                    updateRenderInfo();
                }
            }
        });
    }; };
    (0, react_1.useEffect)(function () {
        // when mounted
        setMounted(true);
        return function () {
            // when unmounted
            setMounted(false);
        };
    }, []);
    (0, react_1.useLayoutEffect)(function () {
        if (mounted) {
            // reset list on list change
            var span_1 = containerRef.current;
            var pos_1 = span_1.parentNode.scrollTop;
            var index = Math.max(render.renderList.length, setupCount);
            setRender({
                renderList: list.slice(0, index),
                index: index,
            });
            requestAnimationFrame(function () {
                if (span_1 && span_1.parentNode) {
                    span_1.parentNode.scrollTop = pos_1;
                }
            });
        }
    }, [list]);
    (0, react_1.useLayoutEffect)(function () {
        var span = containerRef.current;
        var handleScroll = onScroll(span);
        var container = null;
        if (span) {
            container = span.parentNode;
            requestAnimationFrame(function () {
                // populate double the container height of items
                if (render.index === 0 ||
                    container.scrollHeight <= container.offsetHeight * 2) {
                    updateRenderInfo();
                }
                else if (setupCount === -1) {
                    setSetupCount(render.index);
                }
            });
            container.addEventListener("scroll", handleScroll, { passive: true });
        }
        return function () {
            // when unmounted
            if (span) {
                container.removeEventListener("scroll", handleScroll, {
                    passive: true,
                });
            }
        };
    }, [render.index, list.length]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        render.renderList.map(function (item, idx) { return renderThisItem(item, "".concat(idx)); }),
        react_1.default.createElement("span", { ref: containerRef, style: { visibility: "hidden", height: 1 }, className: "___scroll-renderer-anchor" })));
}
exports.default = ScrollRenderer;
