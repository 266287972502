var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React from 'react';
import { TabSelectionContext } from './TabProvider';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

var withTabSelection = function withTabSelection(Component) {
  var TabSelectionComponent = function TabSelectionComponent(props) {
    return React.createElement(
      TabSelectionContext.Consumer,
      null,
      function (selection) {
        return React.createElement(Component, _extends({}, props, { selection: selection }));
      }
    );
  };
  TabSelectionComponent.WrappedComponent = Component;
  TabSelectionComponent.displayName = 'withTabSelection(' + getDisplayName(Component) + ')';
  return TabSelectionComponent;
};

export default withTabSelection;