"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var isType_1 = require("../___utils/isType");
var uiFunctions_1 = require("./uiFunctions");
function ScrollToTopButton(props) {
    var anchor = (0, react_1.createRef)();
    var _a = props.button, button = _a === void 0 ? null : _a, _b = props.position, position = _b === void 0 ? "bottom right" : _b, _c = props.padding, padding = _c === void 0 ? 20 : _c, _d = props.offset, offset = _d === void 0 ? 50 : _d, scrollingContainer = props.scrollingContainer;
    var btn = (0, isType_1.isFunction)(button) ? button() : button;
    var _e = (0, react_1.useState)(false), mounted = _e[0], setMounted = _e[1];
    (0, react_1.useEffect)(function () {
        var buttonElement = anchor.current.nextElementSibling;
        var container = anchor.current.parentNode;
        var scrollContainer = scrollingContainer.current;
        var containerStyle = getComputedStyle(container);
        var ogPos = containerStyle.position;
        container.style.position = ["absolute", "fixed", "relative"].includes(ogPos)
            ? ogPos
            : "relative";
        var positionBtn = (0, uiFunctions_1.btnPosition)(scrollContainer, buttonElement);
        var pos = position.split(" ");
        var updateBtnPosition = function () {
            return positionBtn(pos[0], pos[1], padding, offset);
        };
        window.addEventListener("resize", updateBtnPosition);
        scrollContainer.addEventListener("scroll", updateBtnPosition);
        buttonElement.addEventListener("click", function () {
            scrollContainer.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });
        setTimeout(function () { return updateBtnPosition(); }, 250);
        setMounted(true);
        return function () {
            container.style.position = ogPos;
            window.removeEventListener("resize", updateBtnPosition);
        };
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !mounted && react_1.default.createElement("span", { ref: anchor, style: { display: "none" } }),
        button ? btn : react_1.default.createElement("button", { type: "button" }, "To Top")));
}
exports.default = ScrollToTopButton;
