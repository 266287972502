"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultProps = void 0;
exports.defaultProps = {
    __forwarededRef: { current: null },
    // RENDER
    list: [],
    renderItem: function () { return null; },
    limit: 0,
    renderWhenEmpty: null,
    reversed: false,
    renderOnScroll: false,
    wrapperHtmlTag: "",
    // DISPLAY
    display: {
        grid: false,
        gridGap: "",
        gridMinColumnWidth: "",
        row: false,
        rowGap: "",
    },
    displayGrid: false,
    displayRow: false,
    gridGap: "",
    rowGap: "",
    minColumnWidth: "",
    // FILTER
    filterBy: "",
    // GROUPS
    group: {
        by: "",
        limit: 0,
        of: 0,
        reversed: false,
        separator: null,
        separatorAtTheBottom: false,
        sortedBy: "",
        sortBy: "",
        sortedCaseInsensitive: false,
        sortCaseInsensitive: false,
        sortedDescending: false,
        sortDescending: false, // deprecated
    },
    groupBy: "",
    groupOf: 0,
    groupReversed: false,
    groupSeparator: null,
    groupSeparatorAtTheBottom: false,
    groupSorted: false,
    groupSortedBy: "",
    groupSortedDescending: false,
    groupSortedCaseInsensitive: false,
    showGroupSeparatorAtTheBottom: false,
    // PAGINATION
    pagination: {
        hasMore: false,
        loadMore: null,
        loadingIndicator: null,
        loadingIndicatorPosition: "",
    },
    hasMoreItems: false,
    loadMoreItems: null,
    paginationLoadingIndicator: null,
    paginationLoadingIndicatorPosition: "",
    // SCROLL TO TOP
    scrollToTop: {
        button: null,
        offset: undefined,
        padding: undefined,
        position: undefined,
    },
    scrollToTopButton: null,
    scrollToTopOffset: undefined,
    scrollToTopPadding: undefined,
    scrollToTopPosition: undefined,
    // SEARCH
    search: {
        by: "",
        caseInsensitive: false,
        everyWord: false,
        onEveryWord: false,
        minCharactersCount: 0,
        term: "",
    },
    searchBy: "",
    searchCaseInsensitive: false,
    searchOnEveryWord: false,
    searchTerm: "",
    searchMinCharactersCount: 0,
    searchableMinCharactersCount: 0,
    // SORT
    sort: {
        by: "",
        caseInsensitive: false,
        descending: false,
        groupBy: "",
        groupCaseInsensitive: false,
        groupDescending: false, // deprecated
    },
    sortBy: "",
    sortCaseInsensitive: false,
    sortDesc: false,
    sortDescending: false,
    sortGroupBy: "",
    sortGroupDesc: false,
    sortGroupCaseInsensitive: false, // deprecated
};
