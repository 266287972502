var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabComponent from './TabComponent';
import withTabSelection from './withTabSelection';

export var KeyCode = {
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40
};

var Tab = function (_Component) {
  _inherits(Tab, _Component);

  function Tab(props) {
    _classCallCheck(this, Tab);

    var _this = _possibleConstructorReturn(this, (Tab.__proto__ || Object.getPrototypeOf(Tab)).call(this, props));

    _this.update = _this.update.bind(_this);
    _this.handleClick = _this.handleClick.bind(_this);
    _this.handleKeyDown = _this.handleKeyDown.bind(_this);

    props.selection.register(props.tabFor);
    return _this;
  }

  _createClass(Tab, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.selection.subscribe(this.update);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.props.selection.unsubscribe(this.update);
      this.props.selection.unregister(this.props.tabFor);
    }
  }, {
    key: 'update',
    value: function update() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          focus = _ref.focus;

      this.forceUpdate();
      if (focus && this.props.selection.isSelected(this.props.tabFor)) {
        this.tabComponent.focus();
      }
    }
  }, {
    key: 'handleClick',
    value: function handleClick(event) {
      this.props.selection.select(this.props.tabFor);

      if (this.props.onClick) {
        this.props.onClick(event);
      }
    }
  }, {
    key: 'handleKeyDown',
    value: function handleKeyDown(e) {
      var verticalOrientation = this.props.selection.isVertical();
      if (e.keyCode === KeyCode.HOME) {
        this.props.selection.selectFirst({ focus: true });
      } else if (e.keyCode === KeyCode.END) {
        this.props.selection.selectLast({ focus: true });
      } else if (e.keyCode === KeyCode.LEFT_ARROW && !verticalOrientation) {
        this.props.selection.selectPrevious({ focus: true });
      } else if (e.keyCode === KeyCode.RIGHT_ARROW && !verticalOrientation) {
        this.props.selection.selectNext({ focus: true });
      } else if (e.keyCode === KeyCode.UP_ARROW && verticalOrientation) {
        e.preventDefault();
        this.props.selection.selectPrevious({ focus: true });
      } else if (e.keyCode === KeyCode.DOWN_ARROW && verticalOrientation) {
        e.preventDefault();
        this.props.selection.selectNext({ focus: true });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          tabFor = _props.tabFor,
          children = _props.children,
          className = _props.className,
          props = _objectWithoutProperties(_props, ['tabFor', 'children', 'className']);

      var isSelected = this.props.selection.isSelected(tabFor);

      return React.createElement(
        TabComponent,
        _extends({}, props, {
          tabFor: tabFor,
          onClick: this.handleClick,
          onKeyDown: this.handleKeyDown,
          selected: isSelected,
          className: className,
          tabRef: function tabRef(e) {
            _this2.tabComponent = e;
          }
        }),
        children
      );
    }
  }]);

  return Tab;
}(Component);

Tab.defaultProps = {
  className: '',
  selected: false,
  onClick: undefined
};
Tab.propTypes = {
  selection: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    unregister: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    selectNext: PropTypes.func.isRequired,
    selectPrevious: PropTypes.func.isRequired,
    selectFirst: PropTypes.func.isRequired,
    selectLast: PropTypes.func.isRequired,
    isVertical: PropTypes.func.isRequired
  }).isRequired,
  tabFor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func
};


export default withTabSelection(Tab);