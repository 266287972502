"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFunction = exports.isNilOrEmpty = exports.isEmpty = exports.isNil = exports.isMap = exports.isSet = exports.isArray = exports.isString = exports.isObjectLiteral = exports.isObject = exports.isNumeric = exports.isNumber = exports.isBoolean = void 0;
var getType_1 = __importStar(require("./getType"));
var isBoolean = function (x) { return (0, getType_1.default)(x) === getType_1.types.BOOLEAN; };
exports.isBoolean = isBoolean;
var isNumber = function (x) {
    return (0, getType_1.default)(Number(x)) === getType_1.types.NUMBER && !isNaN(Number(x));
};
exports.isNumber = isNumber;
var isNumeric = function (x) { return isFinite(x) && (0, exports.isNumber)(x); };
exports.isNumeric = isNumeric;
var isObject = function (x) { return (0, getType_1.default)(x) === getType_1.types.OBJECT; };
exports.isObject = isObject;
var isObjectLiteral = function (x) {
    return (0, exports.isObject)(x) && x.constructor === Object;
};
exports.isObjectLiteral = isObjectLiteral;
var isString = function (x) {
    return (0, getType_1.default)(x) === getType_1.types.STRING || x instanceof String;
};
exports.isString = isString;
var isArray = function (x) { return (0, getType_1.default)(x) === getType_1.types.ARRAY; };
exports.isArray = isArray;
var isSet = function (x) { return (0, getType_1.default)(x) === getType_1.types.SET; };
exports.isSet = isSet;
var isMap = function (x) { return (0, getType_1.default)(x) === getType_1.types.MAP; };
exports.isMap = isMap;
var isNil = function (x) {
    return x === null || (0, getType_1.default)(x) === getType_1.types.UNDEFINED;
};
exports.isNil = isNil;
var isEmpty = function (x) {
    return (((0, exports.isString)(x) || (0, exports.isArray)(x)) && x.length === 0) ||
        ((0, exports.isObject)(x) && Object.keys(x).length === 0) ||
        ((0, getType_1.default)(x) === getType_1.types.MAP && x.size === 0) ||
        ((0, getType_1.default)(x) === getType_1.types.SET && x.size === 0) ||
        ((0, getType_1.default)(x) === getType_1.types.NUMBER && isNaN(x));
};
exports.isEmpty = isEmpty;
var isNilOrEmpty = function (x) { return (0, exports.isNil)(x) || (0, exports.isEmpty)(x); };
exports.isNilOrEmpty = isNilOrEmpty;
var isFunction = function (x) { return (0, getType_1.default)(x) === getType_1.types.FUNCTION; };
exports.isFunction = isFunction;
exports.default = {
    isArray: exports.isArray,
    isFunction: exports.isFunction,
    isNil: exports.isNil,
    isEmpty: exports.isEmpty,
    isNilOrEmpty: exports.isNilOrEmpty,
    isNumber: exports.isNumber,
    isObject: exports.isObject,
    isString: exports.isString,
    isSet: exports.isSet,
    isMap: exports.isMap,
};
