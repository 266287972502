var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabPanelComponent from './TabPanelComponent';
import withTabSelection from './withTabSelection';

/* eslint-disable no-nested-ternary */

var TabPanel = function (_Component) {
  _inherits(TabPanel, _Component);

  function TabPanel(props) {
    _classCallCheck(this, TabPanel);

    var _this = _possibleConstructorReturn(this, (TabPanel.__proto__ || Object.getPrototypeOf(TabPanel)).call(this, props));

    _this.update = _this.update.bind(_this);
    return _this;
  }

  _createClass(TabPanel, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.selection.subscribe(this.update);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.props.selection.unsubscribe(this.update);
    }
  }, {
    key: 'update',
    value: function update() {
      this.forceUpdate();
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          tabId = _props.tabId,
          props = _objectWithoutProperties(_props, ['tabId']);

      var selected = this.props.selection.isSelected(tabId);

      return React.createElement(TabPanelComponent, _extends({
        tabId: tabId,
        selected: selected
      }, props));
    }
  }]);

  return TabPanel;
}(Component);

TabPanel.propTypes = {
  selection: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired
  }).isRequired,
  tabId: PropTypes.string.isRequired
};


export default withTabSelection(TabPanel);